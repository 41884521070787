// const apiUrl = 'https://tatback.iknesoft.in/tat-backend/public' //Url de la api
const apiUrl = 'https://backdev.tatopos.com'
//const apiUrl = 'https://backend.tatopos.com'
//const apiUrl = 'http://localhost:8000' //Url local

const file = (route) => `${apiUrl}/uploads/${route}`
const link = (route) => {
    //Función para crear la url
    const formattedRoute = route[0] === '/' ? route : `/${route}`
    return apiUrl + formattedRoute
}

const objectFormData = (params) => {
    //Función para convertir la data en un formdata
    let keys = Object.keys(params)
    let values = Object.values(params)
    let formdata = new FormData()
    for (let i = 0; i < keys.length; i++) {
        let value = values[i] === null
            ? ''
            : values[i]
        value = Array.isArray(values[i])
            ? JSON.stringify(value)
            : value
        formdata.append(keys[i], value)
    }
    return formdata
}

const objectFormDataAlt = (params) => {
    //Función para convertir la data en un formdata
    let keys = Object.keys(params)
    let values = Object.values(params)
    let formdata = new FormData()
    for (let i = 0; i < keys.length; i++) {
        let value = values[i] === null
            ? ''
            : values[i]
        value = Array.isArray(values[i]) || (typeof values[i] === 'object' && values[i].constructor.name !== 'Blob')
            ? JSON.stringify(value)
            : value
        formdata.append(keys[i], value)
    }
    return formdata
}

const objectFormDataArrayFile = params => {         //Función para convertir la data en un formdata
    let keys = Object.keys(params)
    let values = Object.values(params)
    let formdata = new FormData()
    for (let i = 0; i < keys.length; i++) {
        if (values[i] === null)
            formdata.append(keys[i], [])
        else if (Array.isArray(values[i])) {
            values[i].forEach(v => formdata.append(keys[i], v))
        }
        else
            formdata.append(keys[i], values[i])
    }
    return formdata
}

const objectToFormData = (data, form, namespace) => {
    const formData = form || new FormData()
    for (let property in data) {
        const value = data[property]
        const objectKey = namespace
            ? `${namespace}[${property}]`
            : property

        if (typeof value === 'object') {
            if (value instanceof Date) {
                formData.append(objectKey, value.toISOString())
            } else if (value instanceof Blob || value instanceof File) {
                formData.append(objectKey, value)
            } else if (value === null) {
                formData.append(objectKey, '')
            } else {
                objectToFormData(value, formData, objectKey)
            }
        } else {
            formData.append(objectKey, value || '')
        }
    }

    return formData
}

export { apiUrl, link, file, objectFormData, objectFormDataAlt, objectFormDataArrayFile }
